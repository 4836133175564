import templateUrl from './typeaheadDropDown.tpl.html';
import defaultResultRendererTemplateUrl from './defaultResultRenderer.tpl.html';

angular.module('signalview.typeahead').directive('typeaheadDropDown', [
    function () {
        return {
            restrict: 'E',
            terminal: true,
            scope: {
                getSuggestions: '=',
                tabCompletion: '@?',
                eventCategory: '@', // when set typeahead controller reports tab completion actions as events
                onItemSelected: '=',
                onClear: '<?',
                onChange: '<?',
                onEnter: '<?',
                onBlur: '<?',
                onBlurSync: '<?',
                onFocus: '<?',
                typedValue: '=?',
                reset: '=?',
                inputClass: '@?',
                labelId: '@?',
                inputAutoFocus: '@?',
                resultTemplate: '@?',
                placeHolder: '@?',
                skipAutoResize: '=?',
                skipRefocus: '@?',
                skipAutoFocus: '@?',
                keepTypedValue: '@?',
                filterTitle: '@?',
                modelChangeType: '@',
                disabled: '=ngDisabled',
                allowEmptyValue: '@?',
                hideSpinner: '@?',
                trimWhitespace: '<',
            },
            templateUrl,
            controller: 'TypeaheadDropDownController',
            link: function ($scope, elem) {
                $scope.placeholder = $scope.placeHolder || 'Optional';
                $scope.resultRenderingTemplate = defaultResultRendererTemplateUrl;
                if ($scope.resultTemplate) {
                    $scope.resultRenderingTemplate = $scope.resultTemplate;
                }
                if ($scope.typedValue === undefined) {
                    $scope.typedValue = '';
                }

                if ($scope.trimWhitespace === undefined) {
                    $scope.trimWhitespace = true;
                }
                $scope.results = null;
                $scope.keySelectionIndex = null;
                const inputElement = angular.element('.typeahead-input', elem);
                const fixedWidthInp = inputElement.filter(':not(.resizable)');
                const dropDown = angular.element('.dropdown-menu', elem);

                $scope.refocusInput = function () {
                    inputElement.focus();
                };

                $scope.blurInput = function () {
                    const promise = $scope.clearSuggestionsInternal(true, false);
                    if ($scope.onBlur) {
                        // allow other event handlers to run first as blur could be triggered by suggestion selection
                        promise.then($scope.onBlur);
                    }
                    if ($scope.onBlurSync) {
                        $scope.onBlurSync();
                    }
                };

                $scope.onFocusInput = function (disabled, tabCompletion) {
                    if (!disabled && !tabCompletion) {
                        $scope.getSuggestionsInternal(true);
                    }
                    if ($scope.onFocus) {
                        $scope.onFocus();
                    }
                };

                $scope.scrollIntoView = function (val) {
                    if (typeof val === 'number') {
                        const item = dropDown.find('[data-list-index="' + val + '"]')[0];
                        if (item) item.scrollIntoView(false);
                    }
                };

                $scope.onInputKeyPressed = function (event) {
                    if (event && event.keyCode === 27 && $scope.results) {
                        //esc
                        $scope.consumeEvent(event);
                        $scope.clearSuggestionsInternal(false);
                    } else {
                        $scope.keyPressInternal(event);
                    }
                };

                $scope.scrollToPosition = function () {
                    fixedWidthInp[0].scrollLeft = fixedWidthInp[0].scrollWidth;
                };

                inputElement.on('input', function (event) {
                    if ($scope.onClear && event.target.value.trim() === '') {
                        $scope.onClear();
                    }
                });
            },
        };
    },
]);
