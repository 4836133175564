import { sanitizeChartModel } from '@splunk/olly-services';
import chartNameModalTemplateUrl from '../../charting/chartbuilder/chartNameModal.tpl.html';
import chartSaveTooltipTemplateUrl from '../../charting/chartbuilder/chartSaveTooltip.tpl.html';
import curlTemplateUrl from '../detector/curl.tpl.html';
import chartV2TemplateUrl from './chartV2.tpl.html';
import saveChartToDashboardTooltip from './saveChartToDashboardTooltip.tpl.html';
import builderToggleTemplateUrl from './builderToggle.tpl.html';
import alertTooltipTemplate from '../../../common/tooltips/alertTooltipTemplate.html';
import { ngRoute } from '../../../../app/routing/ngRoute';
import { Capability } from '@splunk/olly-services/lib/services/CurrentUser/Capabilities';
import { getProgramArgsForDashboardInTime } from '../../utils/programArgsUtils';

angular
    .module('signalview.chartV2')
    .controller('v2ChartController', [
        '$scope',
        '$timeout',
        'v2ChartAPIWrapper',
        '$log',
        '$location',
        'sfxModal',
        'alertMessageService',
        'visualizationOptionsToUIModel',
        'dashboardSelectorService',
        'dashboardV2Service',
        '$q',
        'snapshotChartSaveService',
        'chartbuilderUtil',
        'chartUtils',
        'API_URL',
        '$http',
        'CHART_DISPLAY_EVENTS',
        'chartDisplayUtils',
        'blockMapToUIModelConverter',
        'programTextUtils',
        'plotUtils',
        '$rootScope',
        'aceUtils',
        'relatedDetectorService',
        'signalviewMetrics',
        'dashboardUtil',
        'defaultTextNoteMarkdown',
        'dashboardMirrorService',
        'chartV2Utils',
        'urlOverridesService',
        'detectorService',
        'timepickerUtils',
        'hasCapability',
        'featureEnabled',
        function (
            $scope,
            $timeout,
            v2ChartAPIWrapper,
            $log,
            $location,
            sfxModal,
            alertMessageService,
            visualizationOptionsToUIModel,
            dashboardSelectorService,
            dashboardV2Service,
            $q,
            snapshotChartSaveService,
            chartbuilderUtil,
            chartUtils,
            API_URL,
            $http,
            CHART_DISPLAY_EVENTS,
            chartDisplayUtils,
            blockMapToUIModelConverter,
            programTextUtils,
            plotUtils,
            $rootScope,
            aceUtils,
            relatedDetectorService,
            signalviewMetrics,
            dashboardUtil,
            defaultTextNoteMarkdown,
            dashboardMirrorService,
            chartV2Utils,
            urlOverridesService,
            detectorService,
            timepickerUtils,
            hasCapability,
            featureEnabled
        ) {
            $scope.chart = sanitizeChartModel($scope.chart);
            $scope.allCharts = [$scope.chart];

            $scope.alertTooltipTemplate = alertTooltipTemplate;
            $scope.chartSaveTooltipTemplateUrl = chartSaveTooltipTemplateUrl;
            $scope.builderToggleTemplateUrl = builderToggleTemplateUrl;
            $scope.orphanedPlots = [];
            $scope.isDirty = true; //sigh
            $scope.labelOptionsExpanded = true;
            $scope.hasCreateChartCapability = false;
            $scope.hasUpdateChartCapability = false;
            $scope.hasDeleteChartCapability = false;
            $scope.hasCreateShareableSnapshotCapability = false;
            const isDashboardTimeWindowEnabled = featureEnabled('dashboardTimeWindow');

            $scope.archivedMetricsWarningEntity =
                $scope.chart.id || $scope.chart.sf_id ? 'chart' : 'newChart';

            $scope.$on(CHART_DISPLAY_EVENTS.ARCHIVED_METRICS_FOUND, function (event, metrics) {
                $scope.showArchivedMetricsWarning = Object.keys(metrics).length > 0;
            });

            const originalChart = v2ChartAPIWrapper($scope.chart);
            const isFirstSave = originalChart.isEphemeral();
            if (
                (isFirstSave && ngRoute.params.toDashboard) ||
                ($scope.dashboard && $scope.dashboard.id)
            ) {
                const dashboardId = isFirstSave ? ngRoute.params.toDashboard : $scope.dashboard.id;

                dashboardMirrorService
                    .getDashboardAppearanceCount(dashboardId)
                    .then(function (result) {
                        if (result.numMirrors > 1) {
                            $scope.isChartInMirror = true;
                            $scope.numMirrors = result.numMirrors;
                            $scope.numGroups = result.numGroups;
                        }
                    });
            }

            const INSTRUMENTATION_PREFIX = 'ui.chartv2.';
            const ERROR_PARSING_INTERVAL = 200;
            const BUILDER_MODE_INTERVAL = 0;
            const PARSING_INTERVAL = 1000;
            const FALLBACK_MODE_INTERVAL = 2000;
            let parsingDebounce = null;
            let jobRunDebounce = null;
            let parseTextDataGeneration = 0;
            let destroyed = false;
            let aceInstance = null;
            let hasErrors = false;
            let detectorsPromise = null;

            $scope.hasUnsavedChanges = true;
            $scope.signalsCollapsed = !!originalChart.getSignalFlow();
            $scope.allowSave = function () {
                return !$scope.altMode && $scope.colorSchemeIsValid();
            };

            $scope.getSaveButtonTooltipTemplate = function () {
                if (
                    $scope.invalidPlots ||
                    !$scope.allowSave() ||
                    !$scope.hasCreateChartCapability
                ) {
                    return chartSaveTooltipTemplateUrl;
                }
                return saveChartToDashboardTooltip;
            };

            $scope.ignoreEditChartWarning = function () {
                $scope.isChartInMirror = false;
            };

            hasCapability(Capability.CREATE_DETECTOR).then(
                (hasCreateDetectorCapability) =>
                    ($scope.hasCreateDetectorCapability = hasCreateDetectorCapability)
            );

            hasCapability(Capability.CREATE_CHART).then(
                (hasCreateChartCapability) =>
                    ($scope.hasCreateChartCapability = hasCreateChartCapability)
            );

            hasCapability(Capability.UPDATE_CHART).then(
                (hasUpdateChartCapability) =>
                    ($scope.hasUpdateChartCapability = hasUpdateChartCapability)
            );

            hasCapability(Capability.DELETE_CHART).then(
                (hasDeleteChartCapability) =>
                    ($scope.hasDeleteChartCapability = hasDeleteChartCapability)
            );

            hasCapability(Capability.CREATE_SHAREABLE_SNAPSHOT).then(
                (hasCreateShareableSnapshotCapability) =>
                    ($scope.hasCreateShareableSnapshotCapability =
                        hasCreateShareableSnapshotCapability)
            );

            $scope.alertState = null;
            $scope.$on('alert state updated', function (evt, smokeyState) {
                $scope.alertState = smokeyState.alertState;
                $scope.alertStateClass = smokeyState.alertState
                    ? smokeyState.alertState.toLowerCase()
                    : '';
                $scope.detectorAlertStates = chartUtils.getAlertMenuState(smokeyState.activeAlerts);
            });

            const SIGNALFLOW_TAB = {
                id: 'signalflow',
                name: 'Plot editor',
            };
            const CHART_OPTIONS_TAB = {
                id: 'options',
                name: 'Chart options',
            };
            const AXES_TAB = {
                id: 'axes',
                name: 'Axes',
            };
            const DATA_TABLE_TAB = {
                id: 'data',
                name: 'Data table',
            };
            const EVENTS_TAB = {
                id: 'events',
                name: 'Events',
            };

            function hideMetricsSidebar() {
                $scope.$emit('hideMetricsSidebar');
            }

            function updatePlotValidity() {
                $scope.invalidPlots = !plotUtils.hasValidPlots($scope.model);
            }

            $scope.sectionTabs = [
                SIGNALFLOW_TAB,
                CHART_OPTIONS_TAB,
                AXES_TAB,
                DATA_TABLE_TAB,
                EVENTS_TAB,
            ];
            const graphOnlyTabs = [AXES_TAB, DATA_TABLE_TAB, EVENTS_TAB];

            function disableGraphOnlyTabs() {
                graphOnlyTabs.forEach((tab) => (tab.disabled = true));
            }

            function enableGraphOnlyTabs() {
                graphOnlyTabs.forEach((tab) => (tab.disabled = false));
            }

            $scope.$on('$destroy', function () {
                destroyed = true;
            });

            $scope.$on(CHART_DISPLAY_EVENTS.SELECT_TAB, function (evt, tabId) {
                $scope.$broadcast('go to tab', tabId);
            });

            $scope.$on(CHART_DISPLAY_EVENTS.SELECT_PREVIOUS_TAB, function () {
                $scope.$broadcast('go to previous tab');
            });

            $scope.$on('tab selected', function (evt, tabId) {
                $scope.sharedState.currentTabId = tabId;

                hideMetricsSidebar();
                if (tabId !== SIGNALFLOW_TAB.id || $scope.mode !== 'builder') {
                    // Show legend
                    if (tabId === DATA_TABLE_TAB.id) {
                        $scope.$broadcast(CHART_DISPLAY_EVENTS.LEGEND_TAB_SELECTED, 'data');
                    } else if (tabId === EVENTS_TAB.id) {
                        $scope.$broadcast(CHART_DISPLAY_EVENTS.LEGEND_TAB_SELECTED, 'event');
                    }
                }
            });

            $scope.$on('plotSignalsSelected', function (evt, signals) {
                chartbuilderUtil.addPlotsForSignals($scope.model.sf_uiModel, signals);
            });

            $scope.$on(
                'plot alt mode trigger',
                function (evt, altModeDisplayedKey, altModeOn = false) {
                    $scope.altMode = altModeOn;
                    $scope.$broadcast('plot alt mode apply', altModeDisplayedKey, $scope.altMode);
                }
            );

            $scope.linkDetector = function (detector) {
                const detectorId = detector ? detector.sf_id || detector.id : null;
                const detectorName = detector ? detector.sf_detector || detector.name : null;
                if ($scope.mode === 'fallback') {
                    const newProgramText = chartUtils.getLinkDetectorSignalFlow(
                        $scope.model.sf_viewProgramText,
                        detectorId,
                        detectorName
                    );
                    $scope.model.sf_viewProgramText = newProgramText;
                    $scope.chart.programText = newProgramText;
                } else {
                    $scope.$broadcast('link detector', detectorId);
                }
            };

            $scope.processJobMessages = function (evt, messages) {
                if ($scope.mode === 'fallback') {
                    updateAceAnnotations(messages);
                } else {
                    const plotKeyToInfoMap = chartbuilderUtil.processJobMessages(
                        $scope.model.sf_uiModel.allPlots,
                        messages
                    );
                    $scope.$broadcast('plotTimeSeriesData', plotKeyToInfoMap);
                }
            };

            function updateAceAnnotations(messages) {
                setAceAnnotations(
                    aceUtils.messagesToAnnotations(
                        messages,
                        chartDisplayUtils.getSampleRate($scope.model)
                    )
                );
            }

            function setAceAnnotations(annotations) {
                if (aceInstance && aceInstance.getSession()) {
                    aceInstance.getSession().setAnnotations(annotations);
                } else {
                    $log.warn('Tried to set annotations before ace was ready!');
                }
            }

            $rootScope.$on('theme update', function () {
                aceUtils.updateTheme(aceInstance);
            });

            $scope.$on('jobFeedbackEvent', $scope.processJobMessages);

            $scope.aceOption = {
                mode: 'python',
                onLoad: function (ace) {
                    aceInstance = ace;
                    aceUtils.updateTheme(aceInstance);
                },
            };

            $scope.setOnChartLegend = function () {
                $scope.$broadcast('initialize on chart legend');
            };

            function chartSaveErrorHandler(errs) {
                if (errs.data) {
                    alertMessageService({
                        title: 'Error',
                        messages: [
                            'Unable to save chart!',
                            errs.data.code + ' : ' + errs.data.message,
                        ],
                    });
                } else {
                    alertMessageService({
                        title: 'Error',
                        messages: ['An unexpected error occurred when saving this chart'],
                    });
                }
            }

            function getErrors() {
                const errors = [];
                if (!originalChart.getName()) {
                    errors.push('A chart name is required.');
                }

                if (
                    originalChart.getSignalFlow().length === 0 &&
                    originalChart.getVisualizationOptions().type !== 'Text'
                ) {
                    errors.push('SignalFlow is required.');
                }

                return errors;
            }

            $scope.updateModelName = function (name) {
                $scope.model.sf_chart = name;
            };

            $scope.sharedChartState = {
                editMode: true,
            };

            $scope.attemptParseToUIModel = function () {
                const globalTime = urlOverridesService.getGlobalTimeAbsolute();
                let absoluteGlobalTime = null;
                if (globalTime) {
                    absoluteGlobalTime = {
                        absoluteStart: globalTime?.start,
                        absoluteEnd: globalTime?.end,
                    };
                }
                const text = $scope.chart.programText;
                if (text.indexOf('#') !== -1) {
                    return $q.reject({
                        status: 406,
                        data: {
                            message: 'Comments cannot be represented in Plot Builder.',
                        },
                    });
                }
                return $http({
                    method: 'POST',
                    url: API_URL + '/v2/signalflow/_/getSignalFlowModel',
                    data: isDashboardTimeWindowEnabled
                        ? {
                              programText: $scope.chart.programText,
                              programArgs: getProgramArgsForDashboardInTime(
                                  absoluteGlobalTime || $scope.model.sf_uiModel.chartconfig
                              ),
                          }
                        : text,
                    headers: {
                        'Content-Type': isDashboardTimeWindowEnabled
                            ? 'application/json'
                            : 'text/plain',
                    },
                });
            };

            $scope.saveAs = function (andClose) {
                $scope.showModal = true;
                return promptToUpdateChartName($scope.model)
                    .then($scope.prepareSave)
                    .then(function () {
                        return promptForDashboard();
                    })
                    .then(function (dashboard) {
                        return saveToDashboard(dashboard, andClose);
                    })
                    .finally(function () {
                        $scope.showModal = false;
                    });
            };

            function promptForDashboard() {
                const modalOptions = {
                    defaultDashboard: (!$scope.snapshot.id ? $scope.dashboard : null) || null,
                    message: 'Choose a dashboard to save this chart to.',
                    title: 'Choose a dashboard',
                };

                return dashboardSelectorService.promptForDashboard(modalOptions).then(
                    function ({ dashboard }) {
                        if (!dashboard || !dashboard.id) {
                            $q.reject('No dashboard selected');
                        } else {
                            return dashboard;
                        }
                    },
                    function () {
                        $log.info('User cancelled dashboard selection to clone to.');
                        $scope.showModal = false;
                        return $q.reject();
                    }
                );
            }

            function saveToDashboard(dashboard, andClose) {
                return originalChart.saveAs().then(function (resp) {
                    const dashboardId = dashboard.id || null;
                    const chartId = resp.data.id;
                    return dashboardV2Service.saveChartToDashboard(dashboard, resp.data).then(
                        function () {
                            if ($scope.dashboard && dashboardId === $scope.dashboard.id) {
                                // if we are saving to the same dashboard we came from, attempt to navigate
                                // back to the same mirror.
                                dashboardUtil.addDashboardSearchParams({
                                    groupId: $scope.groupId,
                                    configId: $scope.configId,
                                });
                            } else {
                                // otherwise, remove all mirror information and let the target dashboard pick
                                dashboardUtil.addDashboardSearchParams({
                                    groupId: null,
                                    configId: null,
                                });
                            }

                            const destination = andClose
                                ? `/dashboard/${dashboardId}`
                                : `/chart/${chartId}`;
                            $location.path(destination);
                        },
                        function () {
                            alertMessageService({
                                title: 'Error',
                                messages: ['Unable to save chart!'],
                            });
                        }
                    );
                }, chartSaveErrorHandler);
            }

            function promptToUpdateChartName(chart) {
                return sfxModal
                    .open({
                        templateUrl: chartNameModalTemplateUrl,
                        controller: 'ChartNameModalController',
                        controllerAs: '$ctrl',
                        resolve: {
                            model: function () {
                                return {
                                    sf_chart: chart.sf_chart,
                                };
                            },
                        },
                        backdrop: 'static',
                        keyboard: true,
                    })
                    .result.then(function (resp) {
                        $scope.updateModelName(resp.sf_chart);
                    });
            }

            function verifyName(chart) {
                if (!chart.sf_chart) {
                    return promptToUpdateChartName(chart);
                } else {
                    return $q.when();
                }
            }

            $scope.prepareSave = function prepareSave() {
                return verifyName($scope.model).then(function () {
                    let switchPromise;

                    if ($scope.mode === 'builder') {
                        $scope.regenerateSignalFlow();
                        $scope.knownLabels = [];
                        originalChart.updateVisualizationOptions($scope.model.sf_uiModel);
                        $scope.editableLabelOptions = $scope.model.sf_uiModel.allPlots;
                        switchPromise = parseText();
                    } else {
                        switchPromise = $q.when();
                    }

                    return switchPromise.then(function () {
                        $scope.updateOriginal();

                        const errors = getErrors();

                        if (errors.length > 0) {
                            alertMessageService({
                                title: 'Error',
                                messages: errors,
                            });
                            return;
                        }
                    });
                });
            };

            $scope.save = function (andClose) {
                if (!$scope.allowSave()) {
                    return;
                }

                if ($scope.isDashboardSnapshot) {
                    saveSnapshot();
                } else {
                    const isFirstSave = originalChart.isEphemeral();

                    if (isFirstSave && $scope.hasCreateChartCapability) {
                        saveNewChart(andClose);
                    } else {
                        updateChart(andClose);
                    }
                }
            };

            function saveSnapshot() {
                $scope
                    .prepareSave()
                    .then(() => {
                        return snapshotChartSaveService.saveChart(
                            $scope.chart,
                            $scope.dashboard,
                            $scope.snapshot,
                            true
                        );
                    })
                    .finally(() => ($scope.showModal = false));
            }

            function saveNewChart(andClose) {
                if (ngRoute.params.toDashboard) {
                    return $scope
                        .prepareSave()
                        .then(function () {
                            return dashboardV2Service.get(ngRoute.params.toDashboard);
                        })
                        .then(function (dashboard) {
                            return saveToDashboard(dashboard, andClose);
                        })
                        .finally(() => ($scope.showModal = false));
                } else {
                    return $scope.saveAs(andClose);
                }
            }

            function updateChart(andClose) {
                if (!$scope.hasUpdateChartCapability) {
                    return;
                }

                $scope.showModal = true;

                return $scope
                    .prepareSave()
                    .then(function () {
                        return originalChart.save().then(function () {
                            if (andClose) {
                                //goto dashboard
                                const id = $scope.dashboard.id;

                                if (id) {
                                    $location.path('/dashboard/' + id);
                                } else {
                                    $log.error('unable to find dashboard');
                                }
                            } else {
                                ngRoute.reload();
                            }
                        }, chartSaveErrorHandler);
                    })
                    .finally(() => ($scope.showModal = false));
            }

            $scope.model = {
                sf_chart: originalChart.getName(),
                sf_id: originalChart.getId(),
                sf_description: originalChart.getDescription() || '',
                sf_uiModel: {
                    allPlots: [],
                    chartMode: 'graph',
                    chartType: 'line',
                    revisionNumber: 1,
                },
                sf_viewProgramText: originalChart.getSignalFlow() || '',
                sf_flowVersion: 2,
                sf_jobMaxDelay: 0,
            };

            $scope.model.$isOriginallyV2 = true;
            $scope.overriddenChartconfig = {};

            if ($scope.chart) {
                try {
                    const uiModelExtension = visualizationOptionsToUIModel($scope.chart.options);
                    angular.extend($scope.model.sf_uiModel, uiModelExtension);
                    // Used to store the original model chartconfig values for ones that get
                    // overridden, which are needed when saving the chart
                    $scope.overriddenChartconfig = chartUtils.getChartTimeConfig(
                        $scope.model.sf_uiModel.chartconfig
                    );
                } catch (e) {
                    alertMessageService({
                        title: 'An error occurred when trying to read this chart.',
                        messages: [e.message],
                    });
                }
            }

            if (!$scope.model.sf_uiModel.chartconfig) {
                $scope.model.sf_uiModel.chartconfig = {
                    yAxisConfigurations: [],
                };
            }
            const yAxisConfs = $scope.model.sf_uiModel.chartconfig.yAxisConfigurations;
            if (!yAxisConfs || yAxisConfs.length === 0) {
                $scope.model.sf_uiModel.chartconfig.yAxisConfigurations = [
                    {
                        id: 'yAxis0',
                        max: null,
                        min: null,
                        plotlines: {
                            high: null,
                            low: null,
                        },
                    },
                ];
            }

            if ($scope.model.sf_uiModel.chartconfig.yAxisConfigurations.length === 1) {
                $scope.model.sf_uiModel.chartconfig.yAxisConfigurations.push({
                    id: 'yAxis1',
                    max: null,
                    min: null,
                    plotlines: {
                        high: null,
                        low: null,
                    },
                });
            }

            const builderModeWatchers = [];
            let fallbackModeWatchers = [];

            $scope.getConvertableModel = function (suppressErrors) {
                if (!suppressErrors) {
                    signalviewMetrics.incr(INSTRUMENTATION_PREFIX + 'converter.attempted');
                }

                return $scope.attemptParseToUIModel().then(
                    function (resp) {
                        if (destroyed) {
                            return false;
                        }
                        let m;
                        try {
                            m = blockMapToUIModelConverter.convertBlockMap(
                                resp.data,
                                $scope.chart.options
                            );
                        } catch (e) {
                            if (!suppressErrors) {
                                signalviewMetrics.incr(INSTRUMENTATION_PREFIX + 'converter.failed');
                                alertMessageService({
                                    title: 'Error',
                                    messages: ['Unable to convert to builder mode.', e.message],
                                });
                            }
                            return false;
                        }

                        return m;
                    },
                    function (e) {
                        if (!suppressErrors) {
                            signalviewMetrics.incr(INSTRUMENTATION_PREFIX + 'converter.failed');
                            const msgs = [
                                'Unable to convert to builder mode.  Signalflow unparseable into compatible blocks.',
                            ];
                            if (e.status === 406) {
                                msgs.push('The error message was : ' + e.data.message);
                            } else {
                                msgs.push('This was an unexpected error.');
                            }
                            alertMessageService({
                                title: 'Error',
                                messages: msgs,
                            });
                        } else {
                            $scope.$broadcast('builder mode swap');
                        }
                        return false;
                    }
                );
            };

            $scope.switchToBuilderMode = function switchToBuilderMode(isInitial) {
                if (isInitial || ($scope.mode === 'fallback' && !$scope.switchingToBuilderMode)) {
                    $scope.switchingToBuilderMode = true;

                    const isOriginalModelMissingLabels =
                        !originalChart.isEphemeral() &&
                        _.isEmpty($scope.chart.options.publishLabelOptions);

                    $timeout.cancel(parsingDebounce);
                    $scope.updateOriginal();

                    return $scope
                        .getConvertableModel(isInitial)
                        .then(function (convertedModel) {
                            if (!convertedModel) {
                                hideMetricsSidebar();

                                // if the persisted chart model is initially missing publishLabelOptions, then the chartDisplay will not
                                // start the signalflow job in the fallback case. If after parsing the signalfow the labels are available
                                // based on signalflow, then we send an event for the chartdisplay to update data
                                if (
                                    isOriginalModelMissingLabels &&
                                    !_.isEmpty($scope.chart.options.publishLabelOptions)
                                ) {
                                    $scope.$broadcast('builder mode swap');
                                }

                                return $q.when(convertedModel);
                            }

                            unsetFallbackModeWatchers();

                            $scope.mode = 'builder';
                            $scope.model.sf_uiModel.allPlots = convertedModel.allPlots;

                            setBuilderModeWatchers();
                            updateCurrentUniqueKeyForModel();
                            chartbuilderUtil.createTransientIfNeeded($scope.model.sf_uiModel);

                            $scope.$broadcast('builder mode swap');
                            hideMetricsSidebar();
                            $scope.$broadcast('update plot state');
                            return true;
                        })
                        .finally(function () {
                            $scope.switchingToBuilderMode = false;
                        });
                }
                return $q.when(false);
            };

            function unsetFallbackModeWatchers() {
                fallbackModeWatchers.forEach((w) => {
                    w();
                });
                fallbackModeWatchers = [];
            }

            function setBuilderModeWatchers() {
                builderModeWatchers.push(
                    $scope.$watch('model.sf_uiModel.allPlots', $scope.onPlotsChange, true)
                );
                builderModeWatchers.push(
                    $scope.$watch('model.sf_uiModel.chartMode', function (chartMode, oldMode) {
                        $scope.$broadcast('chartModeChanged', chartMode, oldMode);
                    })
                );
            }

            function updateCurrentUniqueKeyForModel() {
                const allPlots = $scope.model.sf_uiModel.allPlots;

                if (!allPlots || allPlots.length === 0) {
                    $scope.model.sf_uiModel.currentUniqueKey = 1;
                } else {
                    let maxKey = 1;
                    $scope.model.sf_uiModel.allPlots.forEach((plot) => {
                        if (plot.uniqueKey > maxKey) {
                            maxKey = plot.uniqueKey;
                        }
                    });

                    $scope.model.sf_uiModel.currentUniqueKey = maxKey + 1;
                }
            }

            $scope.switchToFallbackMode = function switchToFallbackMode() {
                if (!$scope.switchingToBuilderMode) {
                    builderModeWatchers.forEach((w) => {
                        w();
                    });
                    try {
                        $scope.regenerateSignalFlow();
                        $scope.knownLabels = [];
                        originalChart.updateVisualizationOptions($scope.model.sf_uiModel);
                        $scope.editableLabelOptions = $scope.model.sf_uiModel.allPlots;

                        return parseText().then(
                            function () {
                                $scope.$broadcast('builder mode swap');
                                hideMetricsSidebar();
                                $scope.mode = 'fallback';
                                $scope.invalidPlots = false;
                                return true;
                            },
                            function () {
                                return false;
                            }
                        );
                    } catch (e) {
                        $log.error('An error occurred when converting to fallback mode!');
                        return $q.when(false);
                    }
                }
                return $q.when(false);
            };

            $scope.$on('requestFallbackMode', $scope.switchToFallbackMode);

            $scope.toggleLabelOptionsView = function () {
                $scope.labelOptionsExpanded = !$scope.labelOptionsExpanded;
            };

            function debouncedParsing() {
                $timeout.cancel(parsingDebounce);
                parsingDebounce = $timeout(
                    parseText,
                    hasErrors ? ERROR_PARSING_INTERVAL : PARSING_INTERVAL
                );
            }

            function debouncedJobRuns(sflow) {
                $timeout.cancel(jobRunDebounce);
                jobRunDebounce = $timeout(
                    function () {
                        $scope.parseSuccess = false;
                        $scope.model.sf_viewProgramText = sflow;
                    },
                    $scope.mode === 'builder' ? BUILDER_MODE_INTERVAL : FALLBACK_MODE_INTERVAL
                );
            }

            function parseText(isinit) {
                const sflow = originalChart.getSignalFlow();
                const globalTime = urlOverridesService.getGlobalTimeAbsolute();
                const globalTimeRange = globalTime
                    ? { absoluteStart: globalTime.start, absoluteEnd: globalTime.end }
                    : false;
                const programArgs = getProgramArgsForDashboardInTime(
                    globalTimeRange || $scope.model.sf_uiModel.chartconfig
                );
                const previousErrorState = hasErrors;
                hasErrors = false;
                $scope.statements = [];
                parseTextDataGeneration++;
                const expectedGeneration = parseTextDataGeneration;
                setAceAnnotations([]);
                if (sflow.length > 0) {
                    return chartDisplayUtils.getLabelInformation(sflow, programArgs).then(
                        function (programInfo) {
                            if (!programInfo || parseTextDataGeneration !== expectedGeneration) {
                                return;
                            }

                            chartDisplayUtils.processKnownLabels(
                                programInfo,
                                $scope.model,
                                $scope.orphanedPlots
                            );
                            const seenPublishLabels = {};
                            $scope.editableLabelOptions = [];
                            $scope.model.sf_uiModel.allPlots.forEach(function (plot) {
                                if (
                                    plot._originalLabel &&
                                    !plot._isOrderPlaceHolder &&
                                    !seenPublishLabels[plot._originalLabel]
                                ) {
                                    $scope.editableLabelOptions.push(plot);
                                    seenPublishLabels[plot._originalLabel] = true;
                                }
                            });
                            $scope.knownLabels = programInfo.streamPublishInfo
                                .map((_) => {
                                    return _.label;
                                })
                                .concat(programInfo.eventLabels);
                            chartV2Utils.processKnownLabels(
                                $scope.knownLabels,
                                $scope.model,
                                $scope.orphanedPlots
                            );
                            if (!isinit) {
                                debouncedJobRuns(
                                    sflow +
                                        (previousErrorState
                                            ? '\n# CACHE_BUST : ' + Math.random()
                                            : '')
                                );
                            }
                        },
                        function (resp) {
                            // we need to prevent previous pending promises from affecting state.  a discrepancy
                            // in these two values means there is a pending parse of a newer signalflow
                            if (parseTextDataGeneration !== expectedGeneration) {
                                return;
                            }
                            setAceAnnotations(aceUtils.analyticsErrorToAnnotations(resp));
                            hasErrors = true;
                        }
                    );
                } else {
                    return $q.when(null);
                }
            }

            function initDetectors() {
                return $scope.getConvertableModel(true).then(function (convertedChart) {
                    $scope.convertible = !!convertedChart;
                    let chart = $scope.model;
                    if (convertedChart) {
                        chart = {
                            sf_uiModel: convertedChart,
                        };
                    }
                    const metricsAndFiltersInChart = chartUtils.getMetricsAndFiltersInChart(chart);
                    metricsAndFiltersInChart.filters = metricsAndFiltersInChart.filters.concat(
                        $scope.sourceFilters.map(function (filter) {
                            return filter.query;
                        })
                    );
                    return relatedDetectorService
                        .getDetectorsForContext(
                            metricsAndFiltersInChart.metrics,
                            metricsAndFiltersInChart.filters,
                            $scope.getCurrentQuery,
                            chart
                        )
                        .then(function (alerts) {
                            $scope.alerts = alerts;
                        });
                });
            }

            $scope.mouseEnterBell = function () {
                if (!detectorsPromise) {
                    detectorsPromise = initDetectors();
                }
            };

            $scope.getErrorCaretPosition = function (num) {
                let str = '';
                for (let x = 0; x < num; x++) {
                    str += ' ';
                }
                return str + '^';
            };

            function getDetectorToLinkToDetails(id) {
                if (id === 'new') {
                    return $q.when({});
                } else {
                    return detectorService.get(id).then(function (detector) {
                        let detectorName;
                        if (detector.modelVersion === 2) {
                            detectorName = detector.name;
                        } else {
                            detectorName = detector.sf_detector;
                        }
                        return (detectorInfo = {
                            id: id,
                            name: detectorName,
                        });
                    });
                }
            }

            function onInitialModeDeterminationComplete() {
                if ($scope.mode === 'fallback' && detectorInfo) {
                    let programText;
                    if (detectorInfo.id) {
                        programText = chartUtils.getLinkDetectorSignalFlow(
                            $scope.model.sf_viewProgramText,
                            detectorInfo.id,
                            detectorInfo.name
                        );
                    } else {
                        programText = chartUtils.getLinkDetectorSignalFlow(
                            $scope.model.sf_viewProgramText
                        );
                    }
                    $scope.model.sf_viewProgramText = programText;
                    $scope.chart.programText = programText;
                }

                $scope.$watch('chart.programText', function (programText, oldVal) {
                    if (programText === oldVal) {
                        return;
                    }

                    if ($scope.mode === 'builder') {
                        debouncedJobRuns($scope.chart.programText);
                    } else {
                        debouncedParsing();
                    }
                    // Disable non-applicable tabs when there are no plots
                    if (programText) {
                        CHART_OPTIONS_TAB.disabled = false;
                        if ($scope.model.sf_uiModel.chartMode === 'graph') {
                            enableGraphOnlyTabs();
                        }
                    } else {
                        CHART_OPTIONS_TAB.disabled = true;
                        disableGraphOnlyTabs();
                        $scope.$broadcast('leave disabled tab', SIGNALFLOW_TAB.id);
                    }
                });

                $scope.$watch('model.sf_uiModel.chartMode', function (chartMode, oldMode) {
                    if (chartMode === 'text') {
                        hideMetricsSidebar();
                        if (!angular.isDefined($scope.model.sf_uiModel.markdownText)) {
                            $scope.model.sf_uiModel.markdownText = defaultTextNoteMarkdown;
                        }
                        return;
                    }

                    // Metrics sidebar not applicable to text charts
                    if (oldMode === 'text') {
                        hideMetricsSidebar();
                    }

                    // Axes tab is only applicable to graph charts
                    if (chartMode === 'graph' && $scope.chart.programText) {
                        enableGraphOnlyTabs();
                    } else {
                        disableGraphOnlyTabs();
                        $scope.$broadcast('leave disabled tab', SIGNALFLOW_TAB.id);
                    }

                    cacheOptionsForOldMode(oldMode);

                    if (chartModeValueCache[chartMode]) {
                        retreiveOptionsFromCache(chartMode);
                    } else {
                        // Switch to default when current color by option is no longer
                        // applicable
                        if (
                            (chartMode === 'graph' || chartMode === 'list') &&
                            $scope.model.sf_uiModel.chartconfig.colorByValue
                        ) {
                            $scope.model.sf_uiModel.chartconfig.colorByValue = false;
                        }
                        if (
                            chartMode === 'heatmap' &&
                            $scope.model.sf_uiModel.chartconfig.colorByMetric
                        ) {
                            $scope.model.sf_uiModel.chartconfig.colorByMetric = false;
                        }
                    }

                    if (modeAllowsSecondaryVisualization(chartMode)) {
                        chartbuilderUtil.setSecondaryVisualization(
                            $scope.model.sf_uiModel.chartconfig,
                            oldMode,
                            chartMode,
                            chartModeValueCache
                        );
                    }

                    if (
                        chartMode &&
                        oldMode &&
                        chartMode !== 'text' &&
                        oldMode === 'text' &&
                        $scope.mode === 'fallback'
                    ) {
                        $scope.switchToBuilderMode(true);
                    }
                });

                $scope.$watch(
                    'editableLabelOptions',
                    function () {
                        ($scope.editableLabelOptions || [])
                            .filter((plot) => !plot.transient)
                            .forEach(function (sourceplot) {
                                $scope.model.sf_uiModel.allPlots.forEach(function (targetplot) {
                                    if (sourceplot._originalLabel === targetplot._originalLabel) {
                                        targetplot.yAxisIndex = sourceplot.yAxisIndex;
                                        //name doesn't work in realtime for reasons that arent important for now, as editableLabelOptions is
                                        //unreleased and not expected to work fully.
                                        targetplot._originalLabel = sourceplot._originalLabel;
                                        targetplot.configuration.colorOverride =
                                            sourceplot.configuration.colorOverride;
                                        targetplot.configuration.visualization =
                                            sourceplot.configuration.visualization;
                                        targetplot.configuration.prefix =
                                            sourceplot.configuration.prefix;
                                        targetplot.configuration.suffix =
                                            sourceplot.configuration.suffix;
                                        targetplot.configuration.unitType =
                                            sourceplot.configuration.unitType;
                                        targetplot.configuration.viewMenu =
                                            sourceplot.configuration.viewMenu;
                                    }
                                });
                            });
                    },
                    true
                );
            }

            const chartModeValueCache = {};

            $scope.updateOriginal = function () {
                const model = angular.copy($scope.model);
                // Put back original model chartconfig values, before they were overridden
                angular.extend(model.sf_uiModel.chartconfig, $scope.overriddenChartconfig);
                originalChart.setName(model.sf_chart);
                if (model.sf_uiModel && model.sf_uiModel.chartMode === 'text') {
                    originalChart.setSignalFlow('');
                } else {
                    originalChart.setSignalFlow($scope.chart.programText);
                }
                originalChart.setDescription(model.sf_description);
                const s = angular.copy(model.sf_uiModel);
                s.allPlots = $scope.editableLabelOptions || [];
                originalChart.updateVisualizationOptions(s);
                return originalChart;
            };

            $scope.regenerateSignalFlow = function () {
                $scope.chart.programText = programTextUtils.getV2ProgramText(
                    $scope.model.sf_uiModel,
                    true,
                    false,
                    [],
                    false,
                    false,
                    true
                );
            };

            $scope.onPlotsChange = function () {
                $scope.chart.programText = programTextUtils.getV2ProgramText(
                    $scope.model.sf_uiModel,
                    true,
                    false,
                    [],
                    false,
                    false,
                    true
                );
                chartbuilderUtil.createTransientIfNeeded($scope.model.sf_uiModel);
                updatePlotValidity();
                detectorsPromise = null;
            };

            $scope.showCurl = function () {
                $scope.updateOriginal();
                originalChart.getCUrl().then(function (str) {
                    sfxModal.open({
                        templateUrl: curlTemplateUrl,
                        size: 'md',
                        backdrop: true,
                        controller: [
                            '$scope',
                            'cUrlCmd',
                            function ($scope, cUrlCmd) {
                                $scope.cUrlCmd = cUrlCmd;
                            },
                        ],
                        resolve: {
                            cUrlCmd: function () {
                                return str;
                            },
                        },
                    });
                });
            };

            $scope.colorSchemeIsValid = function () {
                return (
                    ($scope.model.sf_uiModel.chartMode !== 'single' &&
                        $scope.model.sf_uiModel.chartMode !== 'list') ||
                    chartbuilderUtil.singleValColorSchemeIsValid($scope.model)
                );
            };

            $scope.getCurrentTimeRange = function () {
                return timepickerUtils.getCurrentTimeRangeForChartConfig(
                    $scope.model.sf_uiModel.chartconfig
                );
            };

            $scope.model.sf_uiModel.chartconfig.histogramColor =
                $scope.model.sf_uiModel.chartconfig.histogramColor || '#ea1849';

            $scope.invalidPlots = false;

            function cacheOptionsForOldMode(oldMode) {
                chartModeValueCache[oldMode] = {
                    colorByValue: $scope.model.sf_uiModel.chartconfig.colorByValue,
                    colorByMetric: $scope.model.sf_uiModel.chartconfig.colorByMetric,
                    colorByValueScale: angular.copy(
                        $scope.model.sf_uiModel.chartconfig.colorByValueScale
                    ),
                    secondaryVisualization:
                        $scope.model.sf_uiModel.chartconfig.secondaryVisualization,
                    serviceFilter: $scope.model.sf_uiModel.chartconfig.serviceFilter,
                    operationFilter: $scope.model.sf_uiModel.chartconfig.operationFilter,
                    tagFilter: $scope.model.sf_uiModel.chartconfig.tagFilter,
                    mapRange: $scope.model.sf_uiModel.chartconfig.mapRange,
                };
            }

            function retreiveOptionsFromCache(chartMode) {
                const cachedOptions = chartModeValueCache[chartMode];
                const config = $scope.model.sf_uiModel.chartconfig;

                config.colorByValue = cachedOptions.colorByValue;
                config.colorByMetric = cachedOptions.colorByMetric;
                config.colorByValueScale = cachedOptions.colorByValueScale;
                config.secondaryVisualization = cachedOptions.secondaryVisualization;
                config.serviceFilter = cachedOptions.serviceFilter;
                config.operationFilter = cachedOptions.operationFilter;
                config.tagFilter = cachedOptions.tagFilter;
                config.mapRange = cachedOptions.mapRange;
            }

            function modeAllowsSecondaryVisualization(mode) {
                return mode === 'list' || mode === 'single';
            }

            const linkToId = urlOverridesService.getLinkDetector();
            let detectorLinkInfoPromise;
            if (linkToId) {
                detectorLinkInfoPromise = getDetectorToLinkToDetails(linkToId);
            } else {
                detectorLinkInfoPromise = $q.when(null);
            }
            let detectorInfo = null;

            // setup initial state:
            // builder mode/signalflow mode
            // post-update watchers
            // signalflow modifiers(linking detectors)
            $q.all([
                detectorLinkInfoPromise.then(function (info) {
                    detectorInfo = info;
                }),
                parseText(true)
                    .then(
                        function () {
                            return $scope.switchToBuilderMode(true);
                        },
                        function () {
                            $scope.mode = 'fallback';
                        }
                    )
                    .then(
                        function (succeeded) {
                            if (!succeeded) {
                                $scope.mode = 'fallback';
                            }
                        },
                        function () {
                            $scope.mode = 'fallback';
                        }
                    ),
            ]).finally(onInitialModeDeterminationComplete);
        },
    ])
    .directive('v2ChartEditor', [
        'CHART_DISPLAY_EVENTS',
        function (CHART_DISPLAY_EVENTS) {
            return {
                restrict: 'E',
                templateUrl: chartV2TemplateUrl,
                controller: 'v2ChartController',
                link: function ($scope) {
                    $scope.$on(CHART_DISPLAY_EVENTS.RENDER_STATS, function (evt, pl) {
                        $scope.renderStats = pl;
                    });

                    $scope.$on(
                        CHART_DISPLAY_EVENTS.LEGEND_KEYS_RECOMPUTED,
                        function (evt, legendKeys) {
                            $scope.legendKeys = legendKeys;
                        }
                    );
                },
            };
        },
    ]);
