const signalviewConfig = window.signalviewConfig || {};
const orgProxyInfo =
    (window.signalviewDomainInfo && window.signalviewDomainInfo.orgProxyInfo) || {};

// TODO(OZAN) Remove once config system has migrated
let API_URL = orgProxyInfo.apiUrl || signalviewConfig.apiUrl;
let SIGNALFLOW_V2_API_URL =
    orgProxyInfo.signalflowServerUrl || signalviewConfig.signalflowServerUrl || '';
let EXPERIMENTAL_URL =
    orgProxyInfo.experimentalServerUrl || signalviewConfig.experimentalServerUrl || '';
const CUSTOMER_SERVER_API_URL =
    orgProxyInfo.customerServerUrl || signalviewConfig.customerServerUrl || '';
const SUPPORT_SERVER_API_URL = signalviewConfig.supportServerUrl || '';
const INGEST_URL = signalviewConfig.ingestUrl || '';
const INTEGRATIONS_DOC_URL = signalviewConfig.integrationsDocsUrl || '';
const STATIC_RESOURCE_ROOT = signalviewConfig.staticRoot || '';
const MARKETPLACE_PRODUCT_LINK = signalviewConfig.awsMarketplaceUrl || '';

// Superpower overrides
if (window.localStorage) {
    if (window.localStorage['API_URL']) {
        API_URL = window.localStorage['API_URL'];
    }
    if (window.localStorage['SIGNALFLOW_V2_API_URL']) {
        SIGNALFLOW_V2_API_URL = window.localStorage['SIGNALFLOW_V2_API_URL'];
    }
    if (window.localStorage['EXPERIMENTAL_URL']) {
        EXPERIMENTAL_URL = window.localStorage['EXPERIMENTAL_URL'];
    }
}

const GOOGLE_AUTH_ENABLED = signalviewConfig.googleAuthEnabled;
const GOOGLE_AUTH_CLIENT_ID = signalviewConfig.googleAuthClientId;
const REALM_STATUSPAGE_URL = signalviewConfig.realmStatusPageUrl;
export const ENABLED_UI_FEATURES = signalviewConfig.enabledFeatures || [];
export const UI_FEATURE_PROBABILITIES = signalviewConfig.featureProbabilities;
// TODO: Change this to whatsNewDenylist once the signalboost change is
// released from http://gerrit.corp.signalfx.com/c/35229/
export const WHATS_NEW_DENYLIST =
    signalviewConfig.whatsNewBlacklist ||
    signalviewConfig.whatsNewDenyList ||
    signalviewConfig.whatsNewDenylist;
const subdomainLoginSearchEnabled = signalviewConfig.subdomainLoginSearchEnabled;
const MAX_CUSTOM_CATEGORY = signalviewConfig.maxCustomCategoriesCount || 10;
const MAX_METRIC_DESCRIPTION_LENGTH = signalviewConfig.metricDescriptionLengthLimit || 1024;
const MAX_NO_METRIC_MESSAGE_LENGTH = 140;
const MAX_NO_METRIC_LINK_TEXT_LENGTH = 50;
const MAX_NO_METRIC_LINK_URL_LENGTH = 2048;
const APP_URL = (window.location.origin + window.location.pathname + '/').replace(/\/+/g, '/');
export const SUPPORT_EMAIL = 'observability-support@splunk.com';
export const PRODUCT_NAME = 'Splunk Observability Cloud';

export const SPLUNK_APM_PRODUCT_NAME = 'APM';
export const SPLUNK_RUM_PRODUCT_NAME = 'RUM';
export const SPLUNK_SYNTHETICS_PRODUCT_NAME = 'Synthetics';
export const SPLUNK_IMM_INFRASTRUCTURE_PRODUCT_NAME = 'Infrastructure';
export const SPLUNK_PROFILING_PRODUCT_NAME = 'PROFILING';
export const SIGNALFX_GREEN = '#05ce00';
export const ES_INDEXING_DELAY = 1000;

export const DETECTOR_ORIGINS_TYPES = ['Standard', 'AutoDetect', 'AutoDetectCustomization'];
export const DETECTOR_ORIGINS_TYPES_AMOUNT = DETECTOR_ORIGINS_TYPES.length;
export { API_URL };

const IS_HIGH_SECURITY = window.SFX_HIGH_SECURITY;

// flag for olly to check weather ga is disable or not
// ToDo: remove this once userAnalyitcs changes gets deployed in both olly/signalview
export const SHOULD_DISABLE_GA = !!window.signalviewConfig?.shouldDisableGA;
// flag for signalview to check weather ga is disable or not
export const isGAdisabled = IS_HIGH_SECURITY || SHOULD_DISABLE_GA;

export const DOMAIN_ORGANIZATION_ID =
    window.signalviewDomainInfo && window.signalviewDomainInfo.organizationId
        ? window.signalviewDomainInfo.organizationId
        : null;

export const DIRECTION_TYPE = {
    AGGREGATION: 'aggregation',
    TRANSFORMATION: 'transformation',
    BOTH: 'both',
};

angular
    .module('signalview.consts', [])
    .constant('API_URL', API_URL)
    .constant('API_V1_URL', API_URL + '/v1')
    .constant('API_V2_URL', API_URL + '/v2')
    .constant('CUSTOMER_SERVER_API_URL', CUSTOMER_SERVER_API_URL)
    .constant('SUPPORT_SERVER_API_URL', SUPPORT_SERVER_API_URL)
    .constant('STATIC_RESOURCE_ROOT', STATIC_RESOURCE_ROOT)
    .constant('SIGNALFLOW_V2_API_URL', SIGNALFLOW_V2_API_URL)
    .constant('INGEST_URL', INGEST_URL)
    .constant('INTEGRATIONS_DOC_URL', INTEGRATIONS_DOC_URL)
    .constant('MARKETPLACE_PRODUCT_LINK', MARKETPLACE_PRODUCT_LINK)
    .constant('EXPERIMENTAL_URL', EXPERIMENTAL_URL)
    .constant('ES_INDEXING_DELAY', ES_INDEXING_DELAY)
    .constant('CROSS_LINK_INDEXING_DELAY', 2000)
    .constant('MEMBERSHIP_DELAY', 3000)
    .constant('localStorage', window.localStorage)
    .constant('GOOGLE_AUTH_ENABLED', GOOGLE_AUTH_ENABLED)
    .constant('GOOGLE_AUTH_CLIENT_ID', GOOGLE_AUTH_CLIENT_ID)
    .constant('ENABLED_UI_FEATURES', ENABLED_UI_FEATURES)
    .constant('UI_FEATURE_PROBABILITIES', UI_FEATURE_PROBABILITIES)
    .constant('WHATS_NEW_DENYLIST', WHATS_NEW_DENYLIST)
    .constant('IS_DEBUG', window.localStorage && window.localStorage.debug === 'true')
    .constant('APP_URL', APP_URL)
    .constant('IS_PRODUCTION', signalviewConfig.isProductionRealm)
    .constant('SUBDOMAIN_ENABLED', signalviewConfig.subdomainEnabled)
    .constant('SUBDOMAIN_ROOT', signalviewConfig.appRootDomain)
    .constant('HTML2CANVAS', window.html2canvas)
    .constant('Handlebars', window.Handlebars)
    .constant('moment', window.moment)
    .constant('IS_HIGH_SECURITY', window.SFX_HIGH_SECURITY)
    .constant('SALES_NUMBER', '1-650-539-8650')
    .constant('SSO_INFO', window.signalviewSSOInfo)
    .constant('DOMAIN_INFO', window.signalviewDomainInfo)
    .constant('DOMAIN_ORGANIZATION_ID', DOMAIN_ORGANIZATION_ID)
    .constant('SUBDOMAIN_LOGIN_SEARCH_ENABLED', subdomainLoginSearchEnabled)
    .constant('SALESFORCE_COMMUNITY_ISSUER', 'https://saml.salesforce.com')
    .constant('METRIC_TYPE_CONVERSION', {
        nativeToDisplay: {
            HISTOGRAM: 'histogram',
            GAUGE: 'gauge',
            COUNTER: 'counter',
            CUMULATIVE_COUNTER: 'cumulative counter',
        },
        displayToNative: {
            histogram: 'HISTOGRAM',
            gauge: 'GAUGE',
            counter: 'COUNTER',
            'cumulative counter': 'CUMULATIVE_COUNTER',
        },
    })
    .constant(
        'IS_MOBILE',
        /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)
    )
    .constant('SF_REALM_NUMBER', signalviewConfig.realmNumber)
    .constant('REALM', signalviewConfig.realm)
    .constant('SYSTEM_USER_ID', 'AAAAAAAAAAA')
    .constant('METABASE_CONSISTENCY_PRAYER_DURATION_MS', 1000)
    .constant('REALM_ENDPOINTS', signalviewConfig.realmEndpoints)
    .constant('REALM_STATUSPAGE_URL', REALM_STATUSPAGE_URL)
    .constant('MAX_CUSTOM_CATEGORY', MAX_CUSTOM_CATEGORY)
    .constant('MAX_METRIC_DESCRIPTION_LENGTH', MAX_METRIC_DESCRIPTION_LENGTH)
    .constant('MAX_NO_METRIC_MESSAGE_LENGTH', MAX_NO_METRIC_MESSAGE_LENGTH)
    .constant('MAX_NO_METRIC_LINK_TEXT_LENGTH', MAX_NO_METRIC_LINK_TEXT_LENGTH)
    .constant('MAX_NO_METRIC_LINK_URL_LENGTH', MAX_NO_METRIC_LINK_URL_LENGTH)
    .constant('SUPPORT_EMAIL', SUPPORT_EMAIL)
    .constant('PRODUCT_NAME', PRODUCT_NAME)
    .constant('SPLUNK_APM_PRODUCT_NAME', SPLUNK_APM_PRODUCT_NAME)
    .constant('SPLUNK_RUM_PRODUCT_NAME', SPLUNK_RUM_PRODUCT_NAME)
    .constant('SPLUNK_SYNTHETICS_PRODUCT_NAME', SPLUNK_SYNTHETICS_PRODUCT_NAME)
    .constant('INTERNAL_ORG', 'AAAAAAAAAAA')
    .constant('SPLUNK_PROFILING_PRODUCT_NAME', SPLUNK_PROFILING_PRODUCT_NAME)
    .constant('DETECTOR_ORIGINS_TYPES', DETECTOR_ORIGINS_TYPES)
    .constant('DETECTOR_ORIGINS_TYPES_AMOUNT', DETECTOR_ORIGINS_TYPES_AMOUNT);
