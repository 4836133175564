import React from 'react';
import PropTypes from 'prop-types';
import AlertEventModalV2Service from '../../legacy/app/alertEventModalV2/service/alertEventModalV2Service';
import ShowMore from './nextSteps/components/ShowMore';
import AboutThisAlert from './AboutThisAlert';
import AboutThisDetector from './AboutThisDetector';
import BEMHelper from 'react-bem-helper';
import { truncateLongPercentageValues } from './utils';

const className = BEMHelper('alert-modal-v2');

const SECTION_LABEL = 'Message';
const NO_MESSAGE_TEXT = 'No message has been set for this detector.';
const AUTO_CLEARED_MESSAGE = 'This alert has been resolved after a specified time has elapsed.';
const AUTO_CLEARED_DOC_URL =
    'https://quickdraw.splunk.com/redirect/?product=Observability&location=zombie.alert&version=current';
const LENGTH_LIMIT = 500;

export default function AlertEventModalBody(props) {
    const {
        event,
        gotoOtherEvent,
        otherEventInIncident,
        otherEventLink,
        foundDetector,
        createdByName,
        updatedByName,
        createdTimestamp,
        updatedTimestamp,
        createdByUserId,
        updatedByUserId,
        alertEventService,
        sanitizer,
        markdownConverter,
        notificationRecipients,
    } = props;
    const message = alertEventService.getMessage(event);

    return (
        <div {...className('body')}>
            <section {...className('body-section')}>
                <h4 {...className('section-title')}>{SECTION_LABEL}</h4>
                <div {...className('message', { inactive: !message })}>
                    {renderMessage()}
                    {event.properties.is === 'auto resolved' && (
                        <p>
                            <br />
                            {AUTO_CLEARED_MESSAGE}
                            &nbsp;
                            <a
                                href={AUTO_CLEARED_DOC_URL}
                                target="_blank"
                                rel="noopener noreferrer"
                            >
                                Details
                            </a>
                        </p>
                    )}
                </div>
            </section>
            <section {...className('body-section')}>
                <AboutThisAlert
                    parentClass={className}
                    gotoOtherEvent={gotoOtherEvent}
                    otherEventLink={otherEventLink}
                    otherEventInIncident={otherEventInIncident}
                    incidentId={alertEventService.getIncidentId(props.event)}
                    notificationRecipients={notificationRecipients}
                />
            </section>
            <section {...className('body-section')}>
                <AboutThisDetector
                    parentClass={className}
                    foundDetector={foundDetector}
                    createTimestamp={createdTimestamp}
                    updateTimestamp={updatedTimestamp}
                    createdByName={createdByName}
                    updatedByName={updatedByName}
                    createdByUserId={createdByUserId}
                    updatedByUserId={updatedByUserId}
                />
            </section>
        </div>
    );

    function renderMessage() {
        if (!message) {
            return NO_MESSAGE_TEXT;
        }

        return (
            <ShowMore
                text={message}
                maxLength={LENGTH_LIMIT}
                textRenderer={renderMessageMarkup}
            ></ShowMore>
        );
    }

    function renderMessageMarkup(text) {
        const truncated = truncateLongPercentageValues(text);
        return <div dangerouslySetInnerHTML={{ __html: createMarkup(truncated) }} />;
    }

    function createMarkup(text) {
        return sanitizer(markdownConverter.makeHtml(text));
    }
}

AlertEventModalBody.propTypes = {
    alertEventService: PropTypes.instanceOf(AlertEventModalV2Service).isRequired,
    foundDetector: PropTypes.bool.isRequired,
    event: PropTypes.object.isRequired,
    gotoOtherEvent: PropTypes.func,
    otherEventInIncident: PropTypes.object,
    otherEventLink: PropTypes.string,
    createdByName: PropTypes.string.isRequired,
    updatedByName: PropTypes.string,
    createdTimestamp: PropTypes.number.isRequired,
    updatedTimestamp: PropTypes.number,
    createdByUserId: PropTypes.string,
    updatedByUserId: PropTypes.string,
    sanitizer: PropTypes.func.isRequired,
    markdownConverter: PropTypes.object.isRequired,
    notificationRecipients: PropTypes.array,
};
