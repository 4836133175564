import oidcTemplateUrl from '../../../app/oidc/oidc.tpl.html';

const OidcController = [
    '$http',
    '$scope',
    '$q',
    '$log',
    '$location',
    '$window',
    'security',
    'loginService',
    'ssoRedirect',
    'DOMAIN_ORGANIZATION_ID',
    'API_V2_URL',
    function (
        $http,
        $scope,
        $q,
        $log,
        $location,
        $window,
        security,
        loginService,
        ssoRedirect,
        DOMAIN_ORGANIZATION_ID,
        API_V2_URL
    ) {
        const $ctrl = this;

        $ctrl.$onInit = () => {
            $scope.processOidcToken($ctrl.token);
        };

        function exchangeSessionWithToken(token) {
            const tokenParts = token.split(':::');
            function respond() {
                if (!token || tokenParts.length !== 2) {
                    $log.error('Invalid OIDC token for session exchange');
                    return $q.reject(null);
                } else {
                    return $http({
                        method: 'POST',
                        url: API_V2_URL + '/session/oidc/exchange',
                        data: {
                            code: tokenParts[0],
                            orgId: DOMAIN_ORGANIZATION_ID,
                            integrationId: tokenParts[1],
                        },
                    });
                }
            }
            return security.logout().then(respond, respond);
        }

        $scope.processOidcToken = function (token) {
            exchangeSessionWithToken(token).then(
                () => {
                    loginService.initOrgAndUserPostLogin().then(() => {
                        const url = ssoRedirect.getAndClear();
                        if (url) {
                            $location.url(url);
                        } else {
                            $location.path('/home');
                        }
                    });
                },
                (e) => {
                    $window.alert('Sign-in failed. Error: Invalid token.');
                    $log.error('Error exchanging OIDC token', e);
                    $location.path('/signin');
                }
            );
        };
    },
];

// there is no ui for the oidc token exchange
// it's just a redirect to homepage
// with an intermediate step of exchanging
// the identity token for an access token
export default {
    bindings: { token: '<' },
    templateUrl: oidcTemplateUrl,
    controller: OidcController,
};
