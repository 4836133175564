import templateUrl from './sourceEdit.tpl.html';

export default {
    templateUrl,
    bindings: {
        appliedVariables: '=?',
        currentFilter: '<',
        dimensionKeys: '=?',
        hide: '<?',
        disabled: '<?',
        disableTag: '<?',
        emptySubmit: '=?',
        enableUrlParams: '=?',
        filterTitle: '@?',
        getCurrentQuery: '=', // currentquery should return a promise which will provide any additional filtering conditions requested
        inPlaceEdit: '@?',
        matchingDimensions: '=?',
        objectTypes: '<?',
        onExit: '<',
        placeholderText: '@',
        showFilterIcon: '<?',
        skipAutoResize: '<',
        sourceFilters: '=', // sourceFilters should reference an existing array containing sourceFilterItem objects
        sourceName: '=tentativeQueryParam',
        sourceSuggestions: '<?',
        suggestAllOnKey: '<',
        labelId: '@?',
        getCurrentTimeRange: '<?',
    },
    controller: [
        '_',
        '$q',
        '$scope',
        'chartbuilderUtil',
        'urlOverridesService',
        'userAnalytics',
        'sourceFilterService',
        'typeaheadUtils',
        function (
            _,
            $q,
            $scope,
            chartbuilderUtil,
            urlOverridesService,
            userAnalytics,
            sourceFilterService,
            typeaheadUtils
        ) {
            const $ctrl = this;
            let sourceSuggestions;
            let currentSuggestionPromise;

            $ctrl.$onInit = $onInit;
            $ctrl.exitEdit = exitEdit;
            $ctrl.getSourceSuggestions = getSourceSuggestions;
            $ctrl.getValues = getValues;
            $ctrl.initializeFiltersToApply = initializeFiltersToApply;
            $ctrl.isSelected = isSelected;
            $ctrl.onSelect = onSelect;
            $ctrl.removeLastFilter = removeLastFilter;
            $ctrl.toggleSelection = toggleSelection;

            function $onInit() {
                $ctrl.inputAutoFocus = $ctrl.inPlaceEdit || false;
                sourceSuggestions =
                    $ctrl.sourceSuggestions || chartbuilderUtil.getSourceSuggestions;

                $scope.$watchCollection('$ctrl.sourceFilters', function (filters, oldValue) {
                    if (
                        angular.equals(filters, oldValue) ||
                        (_.isEmpty(oldValue) && _.isEmpty(filters))
                    ) {
                        return;
                    }

                    updateUrlParams(filters);
                });

                initializeFiltersToApply();
                updateSourceName();

                $scope.inFiltersContext = false;
            }

            function exitEdit() {
                if ($ctrl.onExit) {
                    $ctrl.onExit();
                }
            }

            function updateUrlParams(sources = []) {
                if (!$ctrl.enableUrlParams) {
                    return;
                }

                urlOverridesService.setSourceFilterOverrideList(sources.filter(isValidFilter));
            }

            function isValidFilter(source) {
                return (
                    source && source.property !== undefined && source.propertyValue !== undefined
                );
            }

            function commitFilter() {
                if (!$ctrl.filterToApply) {
                    return;
                }

                // actual clicked element is shared among other components.
                userAnalytics.event('click', 'add-source-filter-override');

                const filter = $ctrl.filterToApply;

                if (isValidFilter(filter) && !filterAlreadyExists(filter)) {
                    const value = angular.copy(filter.propertyValue);

                    if (angular.isArray(value) && value.length < 2) {
                        filter.propertyValue = value[0] || '';
                    }

                    if (!$ctrl.sourceFilters) {
                        $ctrl.sourceFilters = [];
                    }
                    $ctrl.sourceFilters.push(filter);
                }
            }

            function filterAlreadyExists(newFilter) {
                if (!$ctrl.sourceFilters) {
                    return false;
                }
                return $ctrl.sourceFilters.some((existingFilter) => {
                    return (
                        existingFilter.property === newFilter.property &&
                        angular.equals(existingFilter.propertyValue, newFilter.propertyValue) &&
                        existingFilter.type === newFilter.type &&
                        existingFilter.NOT === newFilter.NOT
                    );
                });
            }

            function getQuery(key) {
                const deferral = $q.defer();

                $q.when($ctrl.getCurrentQuery(key)).then((currentQuery) => {
                    // this path unfortunately needs to get programtext+package specifications in an array, or a query string
                    if (!angular.isArray(currentQuery)) {
                        const queryAnd = [];
                        const query =
                            '' +
                            sourceFilterService.translateSourceFilterObjects($ctrl.sourceFilters);
                        if (query) {
                            queryAnd.push(query);
                        }
                        if ($scope.metricsQuery !== '' && currentQuery) {
                            queryAnd.push(currentQuery);
                        }
                        const queryResult = queryAnd.map((q) => `(${q})`).join(' AND ');

                        deferral.resolve(queryResult);
                    } else {
                        deferral.resolve(currentQuery);
                    }
                });

                return deferral.promise;
            }

            function getFilterValuesToApply() {
                if (!$ctrl.filterToApply) {
                    return [];
                }

                return $ctrl.filterToApply.propertyValue;
            }

            function setFilterToApply(filter) {
                if (filter) {
                    // to reduce complexity always set propertyValue of $ctrl.filterToApply
                    // as an array. On commit, this will be converted to the expected filter
                    // object format (i.e. single source filters will be string values).
                    if (!angular.isArray(filter.propertyValue)) {
                        filter.propertyValue = [filter.propertyValue];
                    }

                    // Retain applyIfExists
                    if ($ctrl.filterToApply) {
                        filter.applyIfExists = $ctrl.filterToApply.applyIfExists;
                    }

                    $ctrl.filterToApply = angular.copy(filter);
                    const propertyValue = $ctrl.filterToApply.propertyValue;

                    if (propertyValue) {
                        $ctrl.filterToApply.propertyValue = propertyValue.filter((f) => f);
                    }
                } else {
                    $ctrl.filterToApply = null;
                }
            }

            function initializeFiltersToApply() {
                setFilterToApply($ctrl.currentFilter);
                setNumFilterValues();
                $ctrl.isMultiSelect = $ctrl.numFilterValues > 1;
                $scope.inValueSearch = !!$ctrl.filterToApply;
            }

            function hasFilterValue(valueToCheck) {
                return getFilterValuesToApply().includes(valueToCheck);
            }

            function removeFilter(valueToRemove) {
                const propertyValue = getFilterValuesToApply();
                const index = propertyValue.indexOf(valueToRemove);

                if (index !== -1) {
                    $ctrl.filterToApply.propertyValue.splice(index, 1);
                    const value = $ctrl.filterToApply.propertyValue;

                    if (!value.length) {
                        $ctrl.filterToApply.propertyValue = [];
                    }
                }

                setNumFilterValues();
            }

            function updateSourceName() {
                if ($ctrl.filterToApply) {
                    let prefix = $ctrl.filterToApply.NOT ? '!' : '';
                    prefix += $ctrl.filterToApply.property + ':';

                    $ctrl.sourceName = '';

                    if (!$ctrl.isMultiSelect) {
                        $ctrl.sourceName = prefix + $ctrl.filterToApply.propertyValue;
                    }
                }
            }

            function addFilterValue(src) {
                if (!src) return;

                const value = src.propertyValue;
                if ($ctrl.filterToApply && !hasFilterValue(value)) {
                    $ctrl.filterToApply.propertyValue.push(value);
                } else {
                    setFilterToApply(src);
                }
                $ctrl.filterToApply.NOT = src.NOT;
                setNumFilterValues();
            }

            function setNumFilterValues() {
                $ctrl.numFilterValues = getFilterValuesToApply().length;

                return $ctrl.numFilterValues;
            }

            function isSelected(result) {
                return hasFilterValue(result.propertyValue || result.value);
            }

            function toggleSelection(selection) {
                const value = selection.propertyValue;

                if (hasFilterValue(value)) {
                    removeFilter(value);
                } else {
                    addFilterValue(selection);
                }

                const filterToApply = $ctrl.filterToApply;

                // toggleSelection always signals a multivalue filter, so we need to update
                // sourceName if we were not already in multifilter mode.
                if (!$ctrl.isMultiSelect && filterToApply) {
                    const prefix = (filterToApply.NOT ? '!' : '') + filterToApply.property + ':';
                    $ctrl.isMultiSelect = true;

                    if ($ctrl.sourceName === $ctrl.suggestAllOnKey) {
                        $ctrl.sourceName = '';
                    } else {
                        $ctrl.sourceName = $ctrl.sourceName.replace(prefix, '');
                    }
                }
            }

            function getValues() {
                return angular.copy($ctrl.filterToApply);
            }

            function onSelect(item) {
                // if an or filter has been selected item will === ''; if a single
                // source has been selected item will be an object containing property
                // and propertyValue; if a new property has been selected it will be an
                // object with object.type === 'property'
                if (!item || (item.property && item.propertyValue)) {
                    if (item) {
                        if ($ctrl.isMultiSelect) {
                            addFilterValue(item);
                        } else {
                            setFilterToApply(item);
                        }
                    }
                    commitFilter();
                    $ctrl.filterToApply = null;
                    $ctrl.sourceName = '';
                    $scope.inValueSearch = false;
                    $ctrl.numFilterValues = 0;
                    $ctrl.isMultiSelect = false;
                } else if (item.type === 'property') {
                    $scope.inValueSearch = true;
                    const valueToApply = (item.NOT ? '!' : '') + item.value + ':';
                    if ($ctrl.filterToApply) {
                        $ctrl.filterToApply.property = item.value;
                    }
                    //this is a hack to force the auto-suggest to re-suggest against the new model value.
                    $scope.$broadcast('setViewValue', 'sourceSuggest', valueToApply);
                }
            }

            function suggestionNotCoveredByVariable(result) {
                return !($ctrl.appliedVariables || []).some((appliedVariable) => {
                    const property = appliedVariable.property;
                    return (
                        property &&
                        result &&
                        (property === result.property || property === result.query)
                    );
                });
            }

            function sortResults(results) {
                const filteredResults = results.filter(suggestionNotCoveredByVariable);
                typeaheadUtils.sort(filteredResults, $ctrl.dimensionKeys);
                return filteredResults;
            }

            function updateInValueSearch(key) {
                if (($scope.inValueSearch && !$ctrl.filterToApply) || !$scope.inValueSearch) {
                    $scope.inValueSearch = key && key.match(/:/) !== null;
                }
            }

            function getVariableBias() {
                return ($ctrl.appliedVariables || [])
                    .filter((f) => f.property && f.value)
                    .map(function (f) {
                        const positiveFilter =
                            f.property + ':' + sourceFilterService.generateTermsForQuery(f.value);
                        if (f.replaceOnly) {
                            return `((NOT _exists_:${f.property}) OR (${positiveFilter}))`;
                        }
                        return positiveFilter;
                    })
                    .join(' OR ');
            }

            function expireOutstandingSuggestion() {
                if (currentSuggestionPromise) {
                    currentSuggestionPromise.reject();
                    currentSuggestionPromise = null;
                }
            }

            function getSourceSuggestions(key) {
                if (key && key === $ctrl.suggestAllOnKey) {
                    key = key.replace(/:.*/, ':');
                } else if ($ctrl.isMultiSelect) {
                    const f = $ctrl.filterToApply;
                    key = (f.NOT ? '!' : '') + f.property + ':' + $ctrl.sourceName;
                }

                const deferral = $q.defer();
                const onFetchError = (reason) => deferral.reject(reason);

                expireOutstandingSuggestion();
                currentSuggestionPromise = deferral;

                updateInValueSearch(key);

                getQuery(key)
                    .then(function (query) {
                        // this checks is made to support both forms of queries we currently need.  one uses an array of
                        // programs and the other is a lucene query string
                        if (!angular.isArray(query)) {
                            const variablesBias = getVariableBias();

                            if (variablesBias) {
                                query += ' AND (' + variablesBias + ')';
                            }

                            return sourceSuggestions(
                                query,
                                key,
                                null,
                                {},
                                $ctrl.objectTypes || ['MetricTimeSeries'],
                                $ctrl.disableTag
                            ).then(sortResults);
                        } else {
                            const optionalFilters = [];
                            const filterArray = ($ctrl.sourceFilters || []).map((filt) => ({
                                property: filt.property,
                                values: angular.isArray(filt.propertyValue)
                                    ? filt.propertyValue
                                    : [filt.propertyValue],
                                not: !!filt.NOT,
                            }));

                            if ($ctrl.appliedVariables) {
                                $ctrl.appliedVariables.forEach((variable) => {
                                    if (variable.value) {
                                        const toPush = {
                                            property: variable.property,
                                            values: angular.isArray(variable.value)
                                                ? variable.value
                                                : [variable.value],
                                        };

                                        if (variable.replaceOnly) {
                                            optionalFilters.push(toPush);
                                        } else {
                                            filterArray.push(toPush);
                                        }
                                    }
                                });
                            }
                            let start = 0;
                            let end = 0;
                            if ($ctrl.getCurrentTimeRange) {
                                const timeRange = $ctrl.getCurrentTimeRange();
                                start = timeRange.start;
                                end = timeRange.end;
                            }

                            return chartbuilderUtil.getSuggestionsFromSignalFlow(
                                query,
                                100,
                                key,
                                filterArray,
                                optionalFilters,
                                null,
                                false,
                                null,
                                start,
                                end
                            );
                        }
                    })
                    .catch(onFetchError)
                    .then((results) => {
                        currentSuggestionPromise = null;
                        deferral.resolve(results);
                    })
                    .catch(onFetchError);
                return deferral.promise;
            }

            function removeLastFilter() {
                const filters = getFilterValuesToApply();

                if (filters && filters.length) {
                    removeFilter(filters[filters.length - 1]);
                    updateSourceName();
                    $scope.inFiltersContext = false;
                }
            }
        },
    ],
};
